import { createAction, createSlice } from '@reduxjs/toolkit'
import { AppState } from './storeTypes'
import { HYDRATE } from 'next-redux-wrapper'

const STATE_NAME = 'partnerData'

const initialState = null

const hydrate = createAction<{ partnerData: AppState }>(HYDRATE)

export const partnerSlice = createSlice({
  name: STATE_NAME,
  initialState,
  reducers: {
    setPartner(state, action) {
      return action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(hydrate, (state, action) => {
      return action.payload.partnerData
    })
  }
})

export const { setPartner } = partnerSlice.actions

export const partnerState = (state: AppState) => state.partnerData

export default partnerSlice.reducer
