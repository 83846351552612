import VerticalLayout from './wireframe/VerticalLayout'
import BlankLayout from './wireframe/BlankLayout'
import * as React from 'react'
import { useSession } from 'next-auth/react'
import { useState, useEffect, ReactNode } from 'react'
import { useRouter } from 'next/router'
import Spinner from '../components/global/spinner'

interface Props {
  children: ReactNode
}

const CustomWrapper = ({ children }: Props) => {
  const session = useSession()
  const router = useRouter()
  const [windowReadyFlag, setWindowReadyFlag] = useState<boolean>(false)
  const [isVerticalLayout, setIsVerticalLayout] = useState<boolean>(false);

  useEffect(() => {
    // Event listeners for client-side navigation
    const handleRouteChangeStart = () => {
      setWindowReadyFlag(false);
    };

    const handleRouteChangeComplete = () => {
      setWindowReadyFlag(true);
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    // Initial window check (for server-side navigation and first mount)
    if (typeof window !== 'undefined') {
      setWindowReadyFlag(true);
    }

    // Cleanup event listeners
    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [router.events]);


  useEffect(() => {
    if (session?.status === 'authenticated' && session?.data?.user?.countryId && session?.data?.user?.retailerId) {
      setIsVerticalLayout(true);
    } else {
      setIsVerticalLayout(false);
    }

  }, [session.status, session?.data?.user?.countryId, session?.data?.user?.retailerId]);

  if (!windowReadyFlag) {
    return <Spinner />; // Display spinner while window is not ready
  }

  return (
      <>
        {isVerticalLayout ? (
            <VerticalLayout hasMoreRetailers={session?.data?.user?.hasMoreRetailers || false} retailerId={session?.data?.user?.retailerId}>{children}</VerticalLayout>
        ) : (
            <BlankLayout>{children}</BlankLayout>
        )}
      </>
  );
}

export default CustomWrapper
