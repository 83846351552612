import { styled } from '@mui/material/styles'
import Box, { BoxProps } from '@mui/material/Box'
import { ReactNode } from 'react'
import CustomAppBar from "./AppBar";
import * as React from "react";
import Footer from "./Footer";

export type BlankLayoutProps = {
  children: ReactNode
}

const BlankLayoutWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  minHeight: '100vh',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(5),
  backgroundColor: 'white'
}))

const BlankLayout = ({ children }: BlankLayoutProps) => {
  return (
    <>
      <CustomAppBar />
      <BlankLayoutWrapper className='layout-wrapper'>
        <Box className='app-content' sx={{ overflowX: 'hidden', position: 'relative'}}>
          {children}
        </Box>
      </BlankLayoutWrapper>
      <Footer position='fixed'/>
    </>
  )
}

export default BlankLayout