import type { AppProps } from 'next/app'
import { ThemeProvider } from '@mui/material/styles'
import '../styles/globals.css'
import { CssBaseline } from '@mui/material'
import { defaultThemeOptions } from '../layout/defaultTheme'
import Head from 'next/head'
import CustomWrapper from '../layout/CustomWrapper'
import { SessionProvider } from 'next-auth/react'
import { Session } from 'next-auth'
import { NextPage } from 'next'
import { wrapper } from '../store/store'
import { Provider } from 'react-redux'

// ** Extend App Props with Emotion
type ExtendedAppProps = AppProps & {
  session: Session
  Component: NextPage
}

const MyApp = (props: ExtendedAppProps) => {
  const { store } = wrapper.useWrappedStore(props)
  const { Component, pageProps, session } = props
  return (
    <Provider store={store}>
      <SessionProvider session={session} refetchInterval={5 * 60}>
        <Head>
          <title>MYBESTBRANDS.COM Partnerweb</title>
          <meta name='viewport' content='initial-scale=1, width=device-width' />
        </Head>
        <ThemeProvider theme={defaultThemeOptions}>
          <CssBaseline />
          <CustomWrapper>
            <Component {...pageProps} />
          </CustomWrapper>
        </ThemeProvider>
      </SessionProvider>
    </Provider>
  )
}

export default MyApp
