import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import { createWrapper } from 'next-redux-wrapper'
import { useDispatch } from 'react-redux'
import { partnerSlice } from './partnerslice'

const makeStore = () =>
  configureStore({
    reducer: {
      [partnerSlice.name]: partnerSlice.reducer
    },
    devTools: process.env.NODE_ENV !== 'production'
  })

export type AppStore = ReturnType<typeof makeStore>
export type AppState = ReturnType<AppStore['getState']>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action>

export type AppDispatch = AppStore['dispatch']

export const useAppDispatch: () => AppDispatch = useDispatch

export const wrapper = createWrapper<AppStore>(makeStore)
