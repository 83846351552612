import { ReactNode } from 'react'
import { styled } from '@mui/material/styles'
import Box, { BoxProps } from '@mui/material/Box'
import Menu from './Menu'
import CustomAppBar from './AppBar'
import Footer from './Footer'

type LayoutProps = {
  children: ReactNode
  hasMoreRetailers: boolean
  retailerId: string | undefined
}

const VerticalLayoutWrapper = styled('div')({
  height: '100%',
  display: 'flex'
})

const MainContentWrapper = styled(Box)<BoxProps>({
  flexGrow: 1,
  minWidth: 0,
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column'
})

const ContentWrapper = styled('main')(({ theme }) => ({
  flexGrow: 1,
  width: '100%',
  padding: theme.spacing(6),
  transition: 'padding .25s ease-in-out',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  }
}))

const VerticalLayout = (props: LayoutProps) => {
  const { children, hasMoreRetailers, retailerId } = props

  return (
    <>
      <VerticalLayoutWrapper className='layout-wrapper'>
        <Menu hasMoreRetailers={hasMoreRetailers} retailerId={retailerId} />
        <MainContentWrapper className='layout-content-wrapper'>
          <CustomAppBar />
          <ContentWrapper
            className='layout-page-content'
            sx={{
              mx: 'auto',
              '@media (min-width:1440px)': { maxWidth: 1440 },
              '@media (min-width:1200px)': { maxWidth: '100%' }
            }}
          >
            {children}
          </ContentWrapper>
          <Footer position='sticky' />
        </MainContentWrapper>
      </VerticalLayoutWrapper>
    </>
  )
}

export default VerticalLayout
