import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import { AUTH_UNPROTECTED } from '../../lib/constants/endpoints'
import { signOut } from 'next-auth/react'
import Divider from '@mui/material/Divider'
import HomeIcon from '@mui/icons-material/Home'
import BarChartIcon from '@mui/icons-material/BarChart'
import SettingsIcon from '@mui/icons-material/Settings'
import Link from 'next/link'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'
import LanguageIcon from '@mui/icons-material/Language';
import React, {useEffect, useState} from 'react'
import RetailerSwitch from '../../components/global/retailer-dialog'
import { useDispatch, useSelector } from 'react-redux'
import { partnerState, setPartner } from '../../store/partnerslice'
import CountrySwitch from "../../components/global/country-dialog";

type LayoutProps = {
  hasMoreRetailers: boolean
  retailerId: string | undefined
}

const Menu = ({ hasMoreRetailers, retailerId }: LayoutProps) => {
  const drawerWidth = 300
  const [retailerDialogOpen, setRetailerDialogOpen] = useState(false)
  const [countryDialogOpen, setCountryDialogOpen] = useState(false)
  const [hasMoreCountries, setHasMoreCountries] = useState(false)

  const partnerData = useSelector(partnerState)
  const dispatch = useDispatch()

  const onRetailerButtonClick = () => {
    setRetailerDialogOpen(true)
    setCountryDialogOpen(false)
  }

  const onCountryButtonClick = () => {
    setCountryDialogOpen(true)
    setRetailerDialogOpen(false)
  }

  const closeRetailerDialog = () => {
    setRetailerDialogOpen(false)
  }

  const closeCountryDialog = () => {
    setCountryDialogOpen(false)
  }

  const onLogOutClick = async () => {
    dispatch(setPartner(null))
    return await signOut({
      redirect: true,
      callbackUrl: AUTH_UNPROTECTED.LOG_IN
    })
  }

  const moreCountries = () => {
    const partner = partnerData?.find((partner) => partner.retailerId === retailerId)
    if (!partner) return false
    return partner?.countries?.length > 1
  }

  useEffect(() => {
    setHasMoreCountries(moreCountries())
  }, [retailerId, partnerData]);

  return (
    <>
      <Drawer
        sx={{
          width: drawerWidth,
          backgroundColor: '#f5f2f2',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: '#f5f2f2'
          }
        }}
        variant='permanent'
        anchor='left'
      >
        {/*<Toolbar*/}
        {/*  sx={{*/}
        {/*    justifyContent: 'center',*/}
        {/*    p: theme => `${theme.spacing(0, 6)} !important`*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <Typography variant='h6' color='gray' component='div'>*/}
        {/*    {user?.retailerName}*/}
        {/*  </Typography>*/}
        {/*</Toolbar>*/}
        <List>
          <ListItem key='home' disablePadding>
            <ListItemButton component={Link} href='/home/'>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary='HOME' />
            </ListItemButton>
          </ListItem>
          <ListItem key='statistics' disablePadding>
            <ListItemButton component={Link} href='/statistics/'>
              <ListItemIcon>
                <BarChartIcon />
              </ListItemIcon>
              <ListItemText primary='Statistics' />
            </ListItemButton>
          </ListItem>
          <ListItem key='accountsettings' disablePadding>
            <ListItemButton component={Link} href='/accountsettings/'>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary='Account Settings' />
            </ListItemButton>
          </ListItem>
        </List>
        {hasMoreRetailers && (
          <>
            <Divider />
            <List>
              <ListItem key='retailer' disablePadding>
                <ListItemButton onClick={onRetailerButtonClick}>
                  <ListItemIcon>
                    <ChangeCircleIcon />
                  </ListItemIcon>
                  SWITCH RETAILER
                </ListItemButton>
              </ListItem>
            </List>
          </>
        )}
        {hasMoreCountries && (
            <>
              <Divider />
              <List>
                <ListItem key='retailer' disablePadding>
                  <ListItemButton onClick={onCountryButtonClick}>
                    <ListItemIcon>
                      <LanguageIcon />
                    </ListItemIcon>
                    Change country
                  </ListItemButton>
                </ListItem>
              </List>
            </>
        )}
        <Divider />
        <List>
          <ListItem key='logout' disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary='Logout' onClick={onLogOutClick} />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      {retailerDialogOpen && hasMoreRetailers && partnerData && (
        <RetailerSwitch closeDialog={closeRetailerDialog} retailerDialogOpen={retailerDialogOpen} />
      )}
      {countryDialogOpen && hasMoreCountries && partnerData && (
          <CountrySwitch closeDialog={closeCountryDialog} countryDialogOpen={countryDialogOpen} retailerId={retailerId} />
      )}
    </>
  )
}

export default Menu
