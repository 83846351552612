import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

const Spinner = () => {
    return (
        <Box
            sx={{
                height: '50vh',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center'
            }}
        >
            <CircularProgress size={80} disableShrink sx={{ mt: 4 }} />
        </Box>
    )
}

export default Spinner