export const API_ENDPOINTS = {
  AUTH: {
    LOGIN: `/api/auth/login/`,
    LOGOUT: `/api/auth/logout/`,
    SELECT_COUNTRY: `/api/auth/checkCountry`,
    COUNTRIES: `/api/auth/countries`
  },
  PARTNER_CREATE: '/api/partner/create/',
  SET_BUDGET_LIMIT: '/api/dashboard/budget/',
  SET_CAMPAIGN_STATUS: '/api/dashboard/campaign/',
  GET_CLICKOUTS_DATA: '/api/statistics/clickouts/',
  GENERATE_RESET_LINK: '/api/user/generate-link/',
  RESET_PASSWORD: '/api/user/reset-password/',
  GENERATE_API_KEY: '/api/dashboard/api-key/',
  GENERATE_ADDITIONAL_USER_TOKEN: '/api/user/send-invitation/',
  CREATE_ADDITIONAL_ACCOUNT: '/api/user/create-additional/'
}

export const AUTH_PROTECTED = {
  MYAREA: '/home'
}

export const AUTH_UNPROTECTED = {
  LOG_IN: '/login',
  FORGOT_PASSWORD: '/forgot-password'
}

export const AUTH_LIMBO = {
  ADDITIONAL_SELECTION: '/select'
}
