import {styled, useTheme} from "@mui/material/styles";
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar';
import Link from "next/link";

const StyledLink = styled('a')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    marginRight: theme.spacing(8)
}))

const CustomAppBar = () => {
    const theme = useTheme()

    return (
        <AppBar elevation={3} color='default' position='sticky' sx={{backgroundColor: '#f5f2f2'}}>
            <Toolbar
                sx={{
                    justifyContent: 'center',
                    p: theme => `${theme.spacing(0, 6)} !important`,
                    minHeight: `${theme.mixins.toolbar.minHeight}px !important`
                }}
            >
                <Link
                    href='/'
                    passHref
                    legacyBehavior
                >
                    <StyledLink sx={{marginRight: `0px !important`}}>
                        <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" width="200" height="20"
                             viewBox="0 0 741.09 42.89"><title>MBB_Logo</title>
                            <polygon
                                points="26.47 25.77 8.42 1.02 0 1.02 0 41.93 6.78 41.93 6.78 9.28 6.95 9.28 26.41 35.15 46.05 9.28 46.22 9.28 46.22 41.93 53 41.93 53 1.02 44.58 1.02 26.47 25.77"/>
                            <polygon
                                points="94.19 20.11 78.41 1.02 69.98 1.02 90.75 25.77 90.75 41.93 97.53 41.93 97.53 25.77 118.29 1.02 109.87 1.02 94.19 20.11"/>
                            <polygon
                                points="202.06 23.79 226.02 23.79 226.02 17.63 202.06 17.63 202.06 7.18 234.91 7.18 234.91 1.02 195.28 1.02 195.28 41.93 235.69 41.93 235.69 35.77 202.06 35.77 202.06 23.79"/>
                            <polygon
                                points="314.59 7.18 334.14 7.18 334.14 41.93 340.92 41.93 340.92 7.18 360.47 7.18 360.47 1.02 314.59 1.02 314.59 7.18"/>
                            <polygon
                                points="606.41 34.58 575.17 1.02 566.75 1.02 566.75 41.93 573.53 41.93 573.53 8.36 604.83 41.93 613.2 41.93 613.2 1.02 606.41 1.02 606.41 34.58"/>
                            <path
                                d="M688.62,23.69H665.16V64.6h24.76a22.77,22.77,0,0,0,15.26-5.43,18.76,18.76,0,0,0,6.61-14.58C711.78,31.94,702.91,23.69,688.62,23.69Zm1.86,34.75H671.94V29.85h17.24c9.15,0,15.26,5.88,15.26,14.58C704.44,52.62,698.62,58.44,690.48,58.44Z"
                                transform="translate(-31.91 -22.67)"/>
                            <path
                                d="M758,41.6c-8.31-1.07-15-.56-19.49-2.15a5,5,0,0,1-3.56-4.61c0-3.39,4.46-6.39,12.94-6.39a41.43,41.43,0,0,1,19,4.52l3.69-4.26c-5.93-4-13.34-6-22.15-6-13.28,0-20.62,4.8-20.62,12.43,0,7.85,6.33,11.13,18.93,12,12,.79,19,.14,19,6,0,4.41-5.2,6.58-14.92,6.58-7.46,0-13-1.34-19.57-5.35l-3.73,4.92c5.93,4.69,12.85,6.37,23.24,6.37,14.52,0,22.32-4.38,22.32-13C773,45.75,768.14,42.9,758,41.6Z"
                                transform="translate(-31.91 -22.67)"/>
                            <path
                                d="M316.1,41.6c-8.31-1.07-15-.56-19.49-2.15A5,5,0,0,1,293,34.85c0-3.39,4.46-6.39,12.94-6.39A41.43,41.43,0,0,1,325,33l3.69-4.26c-5.93-4-13.33-6-22.15-6-13.28,0-20.62,4.8-20.62,12.43,0,7.85,6.33,11.13,18.93,12,12,.79,19,.14,19,6,0,4.41-5.2,6.58-14.92,6.58-7.46,0-13-1.34-19.57-5.35l-3.73,4.92c5.93,4.69,12.85,6.37,23.24,6.37,14.52,0,22.32-4.38,22.32-13C331.13,45.75,326.27,42.9,316.1,41.6Z"
                                transform="translate(-31.91 -22.67)"/>
                            <path
                                d="M562,23.69h-8.53L531.78,64.6h7.4l4.75-9.15h27.52l4.75,9.15h7.4Zm-14.69,25.6,10.45-19.78,10.4,19.78Z"
                                transform="translate(-31.91 -22.67)"/>
                            <path
                                d="M445.64,43.64c1.64-.87,4.43-4.13,4.43-8.71,0-8-6.89-11.27-13.33-11.27h-26V64.57h26c6.93,0,14.85-1.19,15.34-11.44C452.37,47.39,448.2,45.06,445.64,43.64Zm-28.2-13.83H436.3c5,0,7.8,2.09,7.8,5.18,0,3.59-3,5.67-7.8,5.67H417.44Zm18.86,28.6H417.44l0-11.48h18.86c5.58,0,9.13,1.1,9.2,5.68C445.6,57.72,441.78,58.42,436.3,58.42Z"
                                transform="translate(-31.91 -22.67)"/>
                            <path
                                d="M506.26,48.66c4.48-1,8.2-4.86,8.2-12.09,0-7.91-5.2-12.88-13.79-12.88H472.44V64.6h6.78V49h19.07l11.07,15.6h7.85Zm-6.6-5.82H479.22v-13H499c5.71,0,8.48,2.2,8.48,6.72C507.51,40.58,504.74,42.84,499.66,42.84Z"
                                transform="translate(-31.91 -22.67)"/>
                            <path
                                d="M201.52,43.64c1.64-.87,4.43-4.13,4.43-8.71,0-8-6.89-11.27-13.33-11.27h-26V64.57h26c6.93,0,14.85-1.19,15.34-11.44C208.24,47.39,204.08,45.06,201.52,43.64Zm-28.2-13.83h18.86c5,0,7.8,2.09,7.8,5.18,0,3.59-3,5.67-7.8,5.67H173.32Zm18.86,28.6H173.32l0-11.48h18.86c5.58,0,9.13,1.1,9.2,5.68C201.47,57.72,197.66,58.42,192.18,58.42Z"
                                transform="translate(-31.91 -22.67)"/>
                        </svg>
                    </StyledLink>
                </Link>
            </Toolbar>
        </AppBar>
    )
}

export default CustomAppBar;