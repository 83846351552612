import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import AppBar from '@mui/material/AppBar'

const Footer = ({ position, elevation }: any) => {
  return (
    <AppBar
      elevation={elevation}
      position={position}
      color='default'
      sx={{ top: 'auto', bottom: 0, backgroundColor: '#f5f2f2' }}
    >
      <Box
        component='footer'
        className='layout-footer'
        sx={{
          zIndex: 10,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Box
          className='footer-content-container'
          sx={{
            py: 4,
            px: [4, 6],
            width: '100%',
            borderTopLeftRadius: 14,
            borderTopRightRadius: 14,
            maxWidth: 1440
          }}
        >
          <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography sx={{ mr: 2 }}>{`© COPYRIGHT ${new Date().getFullYear()}`}</Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', '& :not(:last-child)': { mr: 4 } }}>
              <Link
                target='_blank'
                href='https://www.mybestbrands.com/it/about/note-legali-condizioni-generali-protezione-dati-personali/'
              >
                Imprint
              </Link>
              <Link
                target='_blank'
                rel='noopener noreferrer'
                href={`${process.env.NEXT_PUBLIC_HOST}/static/tac-partner-it.pdf`}
              >
                Terms & Conditions
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </AppBar>
  )
}

export default Footer
